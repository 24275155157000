<ng-container *ngIf="state">
  <div class="modal-header pb-0 border-0">
    <omgui-close-x
      role="button"
      class="btn close fw-light text-end"
      aria-label="Close"
      (click)="modal.close()"
      stroke="#666666"
      tabindex="0"
    ></omgui-close-x>
  </div>
  <div class="modal-body p-4">
    <img
      *ngIf="state === State.Success"
      class="mb-4"
      src="assets/images/logo-confetti-transparent.svg"
      alt="Login with Amazon success"
    />
    <h2 class="mb-4 text-black" data-cy="lwa-completion-modal-header">{{ header }}</h2>
    <p class="mb-4 text-muted" data-cy="lwa-completion-modal-body">
      {{ body }}
    </p>
    <omgui-button (buttonClick)="modal.close()" [size]="ButtonSize.fillParent">OK</omgui-button>
  </div>
</ng-container>
