import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { LoginWithAmazonAnalyticsService } from '@app/login-with-amazon/login-with-amazon-analytics.service';
import { OmguiButtonSize } from '@omgui/omgui-button/omgui-button.component';

enum State {
  Success = 'success',
  Failure = 'failure',
}

@Component({
  selector: 'om-login-with-amazon-completion-modal',
  templateUrl: 'login-with-amazon-completion-modal.component.html',
})
export class LoginWithAmazonCompletionModalComponent implements OnInit {
  static readonly QUERY_PARAM_KEY = 'action';
  static readonly QUERY_PARAM_SUCCESS_VALUE = 'show-lwa-success';
  static readonly QUERY_PARAM_FAILURE_VALUE = 'show-lwa-failure';

  protected readonly ButtonSize = OmguiButtonSize;
  protected readonly State = State;

  protected state: State;
  protected header: string;
  protected body: string;

  constructor(
    protected modal: NgbActiveModal,
    private route: ActivatedRoute,
    private router: Router,
    private loginWithAmazonAnalyticsService: LoginWithAmazonAnalyticsService,
  ) {}

  static routeHasQueryParam(route: ActivatedRoute): boolean {
    const queryParamValue = route.snapshot.queryParams[LoginWithAmazonCompletionModalComponent.QUERY_PARAM_KEY];
    return [
      LoginWithAmazonCompletionModalComponent.QUERY_PARAM_SUCCESS_VALUE,
      LoginWithAmazonCompletionModalComponent.QUERY_PARAM_FAILURE_VALUE,
    ].includes(queryParamValue);
  }

  ngOnInit() {
    this.loginWithAmazonAnalyticsService.trackLinkingSuccessViewed();

    const queryParamValue = this.route.snapshot.queryParams[LoginWithAmazonCompletionModalComponent.QUERY_PARAM_KEY];

    if (queryParamValue === LoginWithAmazonCompletionModalComponent.QUERY_PARAM_SUCCESS_VALUE) {
      this.state = State.Success;
      this.header = 'You can now log in with Amazon';
      this.body =
        'Use your Amazon username and password to log in to One Medical. Your old One Medical password will no longer work.';
    } else {
      this.state = State.Failure;
      this.header = 'Something went wrong';
      this.body =
        'Due to a technical issue on our end, we couldn’t set up your Amazon account. Please try again or contact customer support.';
    }

    const params = { ...this.route.snapshot.queryParams };
    delete params[LoginWithAmazonCompletionModalComponent.QUERY_PARAM_KEY];
    this.router.navigate([], { queryParams: params });
  }
}
