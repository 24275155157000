import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';

import { BookAppointment_bookAppointment } from '@app/appointment/__generated__/BookAppointment';
import { RescheduleAppointment_rescheduleAppointment } from '@app/appointment/__generated__/RescheduleAppointment';
import { AppointmentBookingStateService } from '@app/appointment/appointment-booking-state-service';
import {
  AppointmentBookingState,
  AppointmentBookingType,
} from '@app/appointment/appointment-booking-state-service/appointment-booking-state';
import { AppointmentRoutingStateService } from '@app/appointment/appointment-routing-state-service/appointment-routing-state.service';
import { AppointmentSearchState } from '@app/appointment/appointment-search-service/appointment-search-state';
import { AppointmentSearchStateService } from '@app/appointment/appointment-search-service/appointment-search-state.service';
import { AppointmentSearchService } from '@app/appointment/appointment-search.service';
import { AppointmentService } from '@app/appointment/appointment.service';
import { BookAppointmentGraphQLService } from '@app/appointment/book-appointment-graphql.service';
import { AppointmentInventory } from '@app/appointment/provider-inventories';
import { RescheduleAppointmentGraphQLService } from '@app/appointment/reschedule-appointment-graphql.service';
import { AppointmentAnalyticsBaseService } from '@app/core/appointment-analytics-base.service';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { UserService } from '@app/core/user.service';
import { ContentBlock } from '@app/home/__generated__/content-block-graphql.service.types';
import { ContentBlockGraphQL } from '@app/home/content-block-graphql.service';
import { formatDate } from '@app/shared/date-format.pipe';
import { Office } from '@app/shared/office';
import { PhoneNumberFormatter } from '@app/shared/phone-number-formatter';
import { Provider } from '@app/shared/provider';

import { phoneNumberValidator } from '../phone-number-validator';
import { ConfirmationErrorModalComponent } from './confirmation-error-modal/confirmation-error-modal.component';

@Component({
  selector: 'om-appointment-confirmation-modal',
  templateUrl: './appointment-confirmation-modal.component.html',
  styleUrls: ['./appointment-confirmation-modal.component.scss'],
})
export class AppointmentConfirmationModalComponent implements OnInit, OnDestroy {
  @Input() office: Office;
  @Input() provider: Provider;
  @Input() selectedInventory: AppointmentInventory;
  @Input() analyticsService: AppointmentAnalyticsBaseService;
  @Input() locationAttestation: string;
  @Input() afterHoursBillingDisclosure: boolean;
  @Input() confirmationHeading = 'Confirm your appointment on';
  @Input() confirmationTimePrefix = '';
  @Input() confirmationCTA = 'Confirm Appointment';
  @Output() modalClosed = new EventEmitter();

  bookingState: AppointmentBookingState;
  confirming: boolean;
  displayError: boolean;
  phoneNumberForm: UntypedFormGroup;
  private searchState: AppointmentSearchState;
  private destroy$ = new Subject<void>();
  formattedStartDate: string;
  remoteVisitBillingContentBlock$: Observable<String> | null;
  afterHoursBillingDisclosureContentBlock$: Observable<String> | null;
  isReschedulingAppointment = false;
  bookingTypeEnumType = AppointmentBookingType;
  bookingType: AppointmentBookingType;

  constructor(
    private appointmentService: AppointmentService,
    private bookingStateService: AppointmentBookingStateService,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private routingStateService: AppointmentRoutingStateService,
    private searchService: AppointmentSearchService,
    private searchStateService: AppointmentSearchStateService,
    private userService: UserService,
    private contentBlockGraphQL: ContentBlockGraphQL,
    private rescheduleAppointmentGraphQLService: RescheduleAppointmentGraphQLService,
    private bookAppointmentGraphQLService: BookAppointmentGraphQLService,
    private featureFlagSelectors: FeatureFlagSelectors,
  ) {}

  ngOnInit() {
    this.confirming = false;
    this.searchState = this.searchStateService.getSearchState();
    this.bookingState = this.bookingStateService.getAppointmentBookingState();
    this.analyticsService.setBookingState(this.bookingState);
    this.analyticsService.setSearchState(this.searchState);
    this.analyticsService.trackAppointmentConfirmationModalViewed();

    this.isReschedulingAppointment = this.bookingState.isRescheduling();
    this.bookingType = this.bookingState.bookingType;

    if (this.isRescheduling()) {
      this.confirmationCTA = 'Reschedule Appointment';
    }

    this.userService
      .getUser()
      .pipe(take(1))
      .subscribe(user => {
        this.bookingState.phoneNumber = user.phoneNumber;
        if (user.phoneNumber) {
          this.bookingState.sendSms = true;
        }
        this.createForm();
      });

    this.formattedStartDate = formatDate(
      this.selectedInventory.start_time,
      "EEEE',' MMM d 'at' h:mmaaa zzz",
      this.selectedInventory.timezone,
    );

    this.remoteVisitBillingContentBlock$ = this.fetchContentBlock$('appointment-type-booking-summary');
    if (this.afterHoursBillingDisclosure) {
      this.afterHoursBillingDisclosureContentBlock$ = this.fetchContentBlock$('after-hours-billing-disclosure');
    }
  }

  fetchContentBlock$(contentBlockTitle: String): Observable<String> | null {
    if (!this.searchState) {
      return null;
    }

    const { service_area_id, appointment_type_id } = this.searchState.searchParams;

    return this.contentBlockGraphQL
      .fetch({
        section: 'appointment-type',
        page: appointment_type_id.toString(),
        serviceAreaId: service_area_id.toString(),
        title: contentBlockTitle,
      })
      .pipe(map((result: ApolloQueryResult<ContentBlock>) => result.data.contentBlock?.copy));
  }

  createForm() {
    const initialNumber = new PhoneNumberFormatter(this.bookingState.phoneNumber).display;
    this.phoneNumberForm = this.formBuilder.group({
      phoneNumber: [initialNumber, [Validators.required, phoneNumberValidator]],
    });

    this.phoneNumberForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.handlePhoneChangeValidation(data.phoneNumber);
      const formatter = new PhoneNumberFormatter(data.phoneNumber);
      this.phoneNumberForm.controls.phoneNumber.setValue(formatter.display, { emitEvent: false });
      this.bookingState.phoneNumber = formatter.tenDigitNumber;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isRescheduling(): boolean {
    const { fromAppointment } = this.bookingStateService.getAppointmentBookingState();
    return !!fromAppointment?.id;
  }

  modalClose() {
    this.modalClosed.emit();
  }

  confirmAppointment() {
    this.confirming = true;
    if (!this.bookingState.sendSms || this.phoneNumberForm.valid) {
      this.submit();
    } else {
      this.confirming = false;
      this.displayError = true;
    }
  }

  trackBookingAppointmentClick() {
    this.analyticsService.bookAppointmentClicked(this.selectedInventory);
  }

  private submit() {
    this.isRescheduling() ? this.analyticsService.rescheduled() : this.trackBookingAppointmentClick();

    const onNext = (appointmentId: string | number): void => {
      const source = this.bookingState.getSource() || 'Appointment Selected Modal';
      const queryParams = this.bookingState.appointmentType.remote
        ? { source, timezone: this.selectedInventory.timezone }
        : { source };
      this.router.navigate([`appointments/${appointmentId}/confirmation`], { queryParams, replaceUrl: true });

      this.bookingStateService.setAppointmentBookingState(new AppointmentBookingState());
      this.routingStateService.resetAppointmentRoutingState();
      this.modalClosed.emit();
    };

    const onError = () => {
      this.analyticsService.bookingFailed();
      this.modalClosed.emit();
    };

    const appointmentAttributes = {
      appointmentTypeId: this.bookingState.getAppointmentTypeId().toString(),
      inventoryId: this.selectedInventory.id.toString(),
      sendSms: this.bookingState.sendSms,
      smsNumber: this.bookingState.sendSms ? this.bookingState.phoneNumber : null,
    };

    this.rescheduleOrBookAppointment$(appointmentAttributes)
      .pipe(
        tap(result => {
          if (!result.success) {
            throw new Error(result.mutationErrors.map(errors => errors.messages.join(', ')).join(', '));
          }
        }),
      )
      .subscribe({
        next: result => {
          const appointmentId = result.appointment.id;
          this.analyticsService.appointmentBooked(
            +appointmentId,
            this.selectedInventory.id,
            +result.appointment.appointmentType.id,
          );

          onNext(appointmentId);
        },
        error: error => {
          onError();
          this.showErrorModal(error);
        },
      });
  }

  private rescheduleOrBookAppointment$(appointmentAttributes: {
    sendSms: boolean;
    inventoryId: string;
    smsNumber?: string;
    appointmentTypeId: string;
  }): Observable<RescheduleAppointment_rescheduleAppointment | BookAppointment_bookAppointment> {
    if (this.isRescheduling()) {
      return this.rescheduleAppointmentGraphQLService
        .mutate({
          input: {
            fromAppointmentId: this.bookingState.getFromAppointmentId().toString(),
            appointmentCancellationReasonId: this.bookingState.getCancellationReasonId().toString(),
            newAppointmentAttributes: appointmentAttributes,
          },
        })
        .pipe(map(result => result.data.rescheduleAppointment));
    } else {
      return this.bookAppointmentGraphQLService
        .mutate({
          input: {
            appointmentAttributes: {
              ...appointmentAttributes,
              reason: this.bookingState.reason,
            },
          },
        })
        .pipe(map(result => result.data.bookAppointment));
    }
  }

  setDisplayError() {
    this.displayError = true;
  }

  showErrorModal(error: Error) {
    const modalRef = this.modalService.open(ConfirmationErrorModalComponent, { size: 'lg' });
    modalRef.componentInstance.errorMessage = error.message || 'Looks like something went wrong.';

    modalRef.componentInstance.modalClosed.subscribe(() => {
      modalRef.close();
      this.searchService.getResults(this.searchState);
    });
  }

  private handlePhoneChangeValidation(phoneNumber: string) {
    if (phoneNumber) {
      this.displayError = false;
    }
  }
}
