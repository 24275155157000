import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest, Observable, of as observableOf } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { AuthService } from '@app/core/auth.service';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';

import { UserService } from '../../core/user.service';
import { ServiceArea } from '../service-area';
import { ServiceAreaService } from '../service-area.service';

@Component({
  selector: 'om-service-area-select',
  templateUrl: './service-area-select.component.html',
  styleUrls: ['./service-area-select.component.scss'],
})
export class ServiceAreaSelectComponent implements OnInit {
  @Input() additionalClasses: String;
  @Input() selectedServiceArea: ServiceArea;
  @Input() allowVirtualServiceAreas = true;
  @Input() excludeEnterpriseLocations = false;
  @Input() b2bId: number = null;
  @Input() hasLimitedAccessMembershipPlan = false;

  @Output() selectedServiceAreaChanged = new EventEmitter<ServiceArea>();

  currentSelection: ServiceArea;
  serviceAreas$: Observable<ServiceArea[]>;

  constructor(
    public serviceAreaService: ServiceAreaService,
    private userService: UserService,
    private authService: AuthService,
    private featureFlagSelectors: FeatureFlagSelectors,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit() {
    this.serviceAreas$ = this.serviceAreaService.allServiceAreaOptions$.pipe(
      switchMap(allServiceAreaOptions => this.filterVirtualServiceAreas(allServiceAreaOptions)),
      switchMap(serviceAreaOptions => this.filterEnterpriseOnlyServiceAreas(serviceAreaOptions)),
      switchMap(serviceAreaOptions => this.filterLimitedAccessServiceAreas(serviceAreaOptions)),
    );

    if (this.authService.isLoggedIn()) {
      combineLatest([this.userService.user$, this.serviceAreas$])
        .pipe(first())
        .subscribe(([user, serviceAreas]) => {
          let initialSelectedServiceArea: ServiceArea;
          if (this.selectedServiceArea != null) {
            initialSelectedServiceArea = serviceAreas.find(sa => sa.id === this.selectedServiceArea.id);
          } else if (user != null && user.serviceArea != null) {
            initialSelectedServiceArea = serviceAreas.find(sa => sa.id === user.serviceArea.id);
          }
          if (initialSelectedServiceArea) {
            this.currentSelection = initialSelectedServiceArea;
            this.selectedServiceAreaChanged.emit(this.currentSelection);
          }
        });
      this.userService.getUser();
    }
    this.serviceAreaService.getServiceAreas(this.b2bId);
  }

  setSelectedServiceArea(serviceArea: ServiceArea) {
    // In cypress, sometimes an undefined service area is selected somehow, which should not be possible
    if (serviceArea) {
      this.currentSelection = serviceArea;
      this.selectedServiceAreaChanged.emit(serviceArea);
    }
  }

  private filterVirtualServiceAreas(serviceAreaOptions: ServiceArea[]): Observable<ServiceArea[]> {
    return this.allowVirtualServiceAreas
      ? observableOf(serviceAreaOptions)
      : this.serviceAreaService.nonVirtualServiceAreaOptions$;
  }

  private filterEnterpriseOnlyServiceAreas(serviceAreaOptions: ServiceArea[]): Observable<ServiceArea[]> {
    return this.excludeEnterpriseLocations
      ? this.featureFlagSelectors
          .getFeatureFlag(FeatureFlags.CONSUMER_REGISTRATION_SERVICE_AREA_ADULT_EXCLUSIONS, [])
          .pipe(
            map(excludedServiceAreaCodes =>
              serviceAreaOptions.filter(sa => !excludedServiceAreaCodes.includes(sa.code)),
            ),
          )
      : observableOf(serviceAreaOptions);
  }

  private filterLimitedAccessServiceAreas(serviceAreaOptions: ServiceArea[]): Observable<ServiceArea[]> {
    return this.hasLimitedAccessMembershipPlan
      ? this.launchDarklyService
          .featureFlag$(FeatureFlags.LIMITED_ACCESS_ENROLLMENT_SERVICE_AREAS, {
            enabled: false,
            serviceAreas: [],
          })
          .pipe(
            map(limitedAccessServiceAreas => {
              if (limitedAccessServiceAreas.enabled) {
                return serviceAreaOptions.filter(sa => limitedAccessServiceAreas.serviceAreas.includes(sa.code));
              } else {
                return serviceAreaOptions;
              }
            }),
          )
      : observableOf(serviceAreaOptions);
  }
}
