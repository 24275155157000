import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, noop, Observable, of as observableOf, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AppointmentBookingStateService } from '@app/appointment/appointment-booking-state-service';
import { DATE_FORMAT, TIME_FORMAT } from '@app/appointment/appointment.utils';
import { LinksService } from '@app/core/links.service';
import { OmguiButtonVariant } from '@omgui/omgui-button/omgui-button.component';

import { formatDate } from '../date-format.pipe';
import { AppointmentToRescheduleGraphQLService } from './appointment-to-reschedule-graphql.service';
import { RemoteAppointmentTypeGraphQLService } from './remote-appointment-type-graphql.service';

interface Appointment {
  id: string;
  startAt: string;
  timezoneTzinfo: string;
  office?: {
    name: string;
  };
  provider: {
    displayName: string;
  };
}

@Component({
  selector: 'om-appointment-more-options',
  templateUrl: './appointment-more-options.component.html',
  styleUrls: ['appointment-more-options.component.scss'],
})
export class AppointmentMoreOptionsComponent {
  @Input() set appointment(appointment: Appointment) {
    this._appointment = appointment;
    this.detailsLink = this.links.appointmentConfirmation(appointment.id);
  }

  @Input() displayTimezone: string;
  @Input() disabled: boolean;
  @Input() name = 'More Options';
  @Input() trackingSource: string;

  @Output() viewDetailsClicked = new EventEmitter<void>();
  @Output() cancelClicked = new EventEmitter<void>();
  @Output() cancelConfirmed = new EventEmitter<void>();
  @Output() rescheduleClicked = new EventEmitter<void>();
  @Output() runningLateClicked = new EventEmitter<void>();
  @Output() runningLateConfirmed = new EventEmitter<void>();

  @ViewChild('confirmationModal') modal: TemplateRef<any>;

  modalTitle: string;
  modalBody: string;
  detailsLink: string;
  readonly cancelButtonLabel = 'Cancel';
  readonly rescheduleButtonLabel = 'Reschedule';
  readonly viewDetailsButtonLabel = 'View Details';
  readonly runningLateButtonLabel = "I'm running late";
  readonly ButtonVariant = OmguiButtonVariant;

  private _appointment: Appointment;
  private readonly lateModalTitle = 'Notify us that you are running late to this appointment?';
  private readonly cancelModalTitle = 'Cancel this appointment?';

  constructor(
    private router: Router,
    private bookingStateService: AppointmentBookingStateService,
    private modalService: NgbModal,
    private links: LinksService,
    private appointmentForReschedulingGraphQL: AppointmentToRescheduleGraphQLService,
    private remoteAppointmentTypeGraphQLService: RemoteAppointmentTypeGraphQLService,
  ) {}

  startAppointmentCancellation() {
    this.cancelClicked.emit();
    this.startSwitchCancelToRemoteAppointment();
  }

  startAppointmentReschedule() {
    this.startSwitchRescheduleToRemoteAppointment();
  }

  showRunningLateConfirmation() {
    this.runningLateClicked.emit();
    this.showConfirmationModal(this.lateModalTitle).subscribe({
      next: () => this.runningLateConfirmed.emit(),
    });
  }

  private showConfirmationModal(title: string): Observable<void> {
    const time = formatDate(this._appointment.startAt, `${DATE_FORMAT} ${TIME_FORMAT}`, this.displayTimezone);
    this.modalBody = `${time} with ${this._appointment.provider.displayName}`;
    this.modalTitle = title;

    const subject = new Subject<void>();
    this.modalService
      .open(this.modal)
      .result.then((confirmed: boolean) => {
        if (confirmed) {
          subject.next();
        }
      })
      .catch(noop)
      .finally(() => subject.complete());

    return subject.asObservable();
  }

  private showCancelConfirmation() {
    this.showConfirmationModal(this.cancelModalTitle).subscribe({
      next: () => this.cancelConfirmed.emit(),
    });
  }

  private startSwitchCancelToRemoteAppointment() {
    this.getCorresondingRemoteAppointmentTypeId$().subscribe(remoteAppointmentTypeId => {
      const switchToRemoteCapable = remoteAppointmentTypeId !== null;
      if (switchToRemoteCapable) {
        this.getOffice$().subscribe(office => {
          this.bookingStateService.setStateForRescheduling({ ...this._appointment, office });
          this.router.navigate(['/appointments/switch-to-remote/cancel'], {
            queryParams: { source: this.trackingSource },
          });
        });
      } else {
        this.showCancelConfirmation();
      }
    });
  }

  private startSwitchRescheduleToRemoteAppointment() {
    this.rescheduleClicked.emit();

    combineLatest([this.getOffice$(), this.getCorresondingRemoteAppointmentTypeId$()]).subscribe(
      ([office, remoteAppointmentTypeId]) => {
        this.bookingStateService.setStateForRescheduling({ ...this._appointment, office });
        const switchToRemoteCapable = remoteAppointmentTypeId !== null;
        if (switchToRemoteCapable) {
          this.router.navigate(['/appointments/switch-to-remote/reschedule']);
        } else {
          this.router.navigate(['/appointments/reschedule-reason'], { queryParams: { source: this.trackingSource } });
        }
      },
    );
  }

  private getOffice$(): Observable<{ name: string }> {
    return observableOf(this._appointment).pipe(
      switchMap(({ id, office }) => {
        if (office) {
          return observableOf(office);
        } else {
          return this.appointmentForReschedulingGraphQL
            .fetch({ id })
            .pipe(map(response => response.data.appointment.office));
        }
      }),
    );
  }

  private getCorresondingRemoteAppointmentTypeId$(): Observable<string> {
    return this.remoteAppointmentTypeGraphQLService
      .fetch({ id: this._appointment.id })
      .pipe(map(response => response.data.appointment.appointmentType.remoteAppointmentTypeId));
  }
}
