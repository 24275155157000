import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of as observableOf, switchMap } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { LoginWithAmazonRegistrationGraphqlService } from '@app/registration/login-with-amazon-registration-graphql.service';
import { User } from '@app/shared/user';

import { AuthService } from './auth.service';
import { LinksService } from './links.service';
import { Membership } from './membership';
import { UserService } from './user.service';

@Injectable()
export class RegistrationCompleteGuardService implements CanActivate {
  private desiredRoute: string;
  private claimCode: string;

  constructor(
    private authService: AuthService,
    private links: LinksService,
    private userService: UserService,
    private router: Router,
    private loginWithAmazonRegistrationGraphqlService: LoginWithAmazonRegistrationGraphqlService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.desiredRoute = state.url.split('?')[0];
    this.claimCode = route.queryParams && 'claim_code' in route.queryParams ? route.queryParams['claim_code'] : null;

    // TODO: investigate valid use cases for this guard & refactor.

    // registration check business logic
    const registrationCheck = () =>
      this.userService.getUser(true).pipe(
        switchMap((patient: User) => {
          if (this.isEnterpriseReg(this.desiredRoute) || this.isOnboarding(this.desiredRoute)) {
            return observableOf(this.canActivateEnterpriseRegistration());
          } else if (this.isLegacyRegistration(this.desiredRoute)) {
            return observableOf(this.canActivateRegistration(patient));
          } else if (!patient.isRegComplete) {
            return this.handleIncompleteRegistrationRouting(patient);
          }

          return observableOf(true);
        }),
        catchError(_error => {
          this.authService.goLogin();
          return observableOf(false);
        }),
      );

    // authCheck business logic
    const authCheck = () =>
      this.authService.isLoggedIn$().pipe(
        map(isLoggedIn => {
          if (!isLoggedIn && this.isRegistration(this.desiredRoute)) {
            return true;
          }
          if (!isLoggedIn) {
            return false;
          }
          return null;
        }),
      );

    // control flow return
    return authCheck().pipe(
      concatMap(val => {
        if (val !== null) {
          return observableOf(val);
        }
        return registrationCheck();
      }),
    );
  }

  private canActivateEnterpriseRegistration(): boolean {
    return true;
  }

  private canActivateRegistration(patient: User): boolean {
    if (patient.isRegComplete) {
      this.router.navigateByUrl(this.links.home);
      return false;
    }

    return true;
  }

  private handleIncompleteRegistrationRouting(patient: User): Observable<boolean> {
    return this.isLoginWithAmazonRegistration().pipe(
      map(isLwaReg => {
        if (isLwaReg) {
          this.router.navigateByUrl(this.links.registrationPaidLoginWithAmazon);
        } else if (patient.isExpeditedEnrollmentUser) {
          this.router.navigateByUrl(this.links.registrationExpedited);
        } else if (Membership.hasLimitedAccessPlanType(patient.membershipPlanType)) {
          this.router.navigate([this.links.limitedAccessAmazonRegistration]);
        } else {
          this.router.navigate([this.links.registrationConsumer], { queryParams: { claim_code: this.claimCode } });
        }
        return false;
      }),
    );
  }

  private isLoginWithAmazonRegistration(): Observable<boolean> {
    return this.loginWithAmazonRegistrationGraphqlService
      .fetch()
      .pipe(map(result => !!result.data?.patient?.isLwaRegUser));
  }

  private isRegistration(url: string): boolean {
    return this.isLegacyRegistration(url) || this.isEnterpriseReg(url);
  }

  private isOnboarding(url: string): boolean {
    return url === this.links.onboarding;
  }

  private isLegacyRegistration(url: string): boolean {
    return url === this.links.registrationRoot;
  }

  private isEnterpriseReg(url: string): boolean {
    return url.startsWith(this.links.registrationEnterprise);
  }
}
