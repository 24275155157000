import { Injectable } from '@angular/core';

import { AnalyticsService } from '@app/core/analytics.service';
import {
  FLOW_ACCOUNT_UPDATE,
  LOGIN_AND_SECURITY_PAGE,
  MP_EVENT_PAGE_VIEWED,
  MP_EVENT_VERIFY_PASSWORD_ERROR,
  SWITCH_TO_LWA_MP_FLOW_VERSION,
  VERIFY_YOUR_PASSWORD,
  MP_LWA_DATA_SHARE_REVIEW_PAGE,
  MP_LWA_SHARE_AND_CONTINUE_CLICKED,
  MP_LWA_EMAIL_CLICKED,
  MP_LWA_DATA_SHARING_ERROR_VIEWED,
  MP_LWA_LINKING_CONFIRMATION_PAGE,
} from '@app/core/mixpanel.constants';

@Injectable({
  providedIn: 'root',
})
export class LoginWithAmazonAnalyticsService extends AnalyticsService {
  readonly flow = FLOW_ACCOUNT_UPDATE;
  readonly flowVersion = SWITCH_TO_LWA_MP_FLOW_VERSION;

  trackVerifyIdentityPageViewed() {
    this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: this.flow,
      flow_version: this.flowVersion,
      module: VERIFY_YOUR_PASSWORD,
      source: LOGIN_AND_SECURITY_PAGE,
    });
  }

  trackVerifyPasswordError() {
    this.trackWithDefaultProperties(MP_EVENT_VERIFY_PASSWORD_ERROR, {
      flow: this.flow,
      flow_version: this.flowVersion,
      module: VERIFY_YOUR_PASSWORD,
    });
  }

  trackDataSharingPageViewed() {
    this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: this.flow,
      flow_version: this.flowVersion,
      module: MP_LWA_DATA_SHARE_REVIEW_PAGE,
      source: VERIFY_YOUR_PASSWORD,
    });
  }

  trackShareAndContinueClicked() {
    this.trackWithDefaultProperties(MP_LWA_SHARE_AND_CONTINUE_CLICKED, {
      flow: this.flow,
      flow_version: this.flowVersion,
      module: MP_LWA_DATA_SHARE_REVIEW_PAGE,
    });
  }

  trackEmailClicked() {
    this.trackWithDefaultProperties(MP_LWA_EMAIL_CLICKED, {
      flow: this.flow,
      flow_version: this.flowVersion,
      module: MP_LWA_DATA_SHARE_REVIEW_PAGE,
    });
  }

  trackDataSharingErrorViewed(errorDescription?: string) {
    this.trackWithDefaultProperties(MP_LWA_DATA_SHARING_ERROR_VIEWED, {
      flow: this.flow,
      flow_version: this.flowVersion,
      module: MP_LWA_DATA_SHARE_REVIEW_PAGE,
      error_description: errorDescription,
    });
  }

  trackLinkingSuccessViewed() {
    this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: this.flow,
      flow_version: this.flowVersion,
      module: MP_LWA_LINKING_CONFIRMATION_PAGE,
    });
  }
}
